/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "Aniket Singh",
  title: "Hi all, I'm Aniket",
  subTitle: emoji(
    "I'm a Vision Systems Engineer at Ultium Cells, skilled in Machine Learning and Deep Learning. I'm also engaged in AI research, focusing on Large Language Models (LLMs)."
  ),
  resumeLink:
    "https://drive.google.com/file/d/1qMj3aDUgaSyI2n855TAjH1cR9MGnpCIm/view?usp=share_link", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/singhaniket98",
  linkedin: "https://www.linkedin.com/in/singh-k-aniket",
  gmail: "aniketkashyyap@gmail.com",
  medium: "https://medium.com/@aniketkashyyap",
  instagram: "https://www.instagram.com/aniiiiii_ket/",
  // Instagram, Twitter and Kaggle are also supported in the links!
  // To customize icons and social links, tweak src/components/SocialMedia
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "Vision Systems Engineer and Machine Learning Engineer",
  skills: [
    emoji("⚡ Experience with Full Stack Development"),
    emoji(
      "⚡ Experience with Machine Learning and AI projects, Time Series Analysis"
    ),
    emoji(
      "⚡ Experience with Natural Language Processing, Computer Vision, Generative Adversarial Networks "
    )
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "R",
      fontAwesomeClassname: "fab fa-r-project"
    },
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm"
    },
    {
      skillName: "sql-database",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "aws",
      fontAwesomeClassname: "fab fa-aws"
    },
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "docker",
      fontAwesomeClassname: "fab fa-docker"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "Youngstown State University",
      logo: require("./assets/images/ysulogo.png"),
      subHeader: "Master of Science in Computing and Information Systems",
      duration: "August 2021 - May 2023",
      desc: "GPA: 3.82",
      descBullets: [
        "Specialization in Machine/Deep Learning",
        "Explored Natural Language Processing, Computer Vision, Network Sciences, Recommendations Systems, and Advanced Time Series Analysis",
        "Received Graduate Research Assistanship."
      ]
    },
    {
      schoolName: "Youngstown State University",
      logo: require("./assets/images/ysulogo.png"),
      subHeader: "Bachelor of Science in Computer Science",
      duration: "August 2017 - May 2021",
      desc: "GPA: 3.65",
      descBullets: [
        "Minored in Mathematics and Certified Data Analyst",
        "Received multipe leadership awards and academic awards.",
        "Studied Software Engineering, Data Structures and Algorithms, and some advanced mathematics"
      ]
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Machine Learning and AI",
      progressPercentage: "90%"
    },
    {
      Stack: "Programming, Data Structures and Algorithms",
      progressPercentage: "90%"
    },
    {
      Stack: "Software Engineering - Backend", //Insert stack or technology you have experience in
      progressPercentage: "80%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Software Engineering - Frontend",
      progressPercentage: "60%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Vision Systems Engineer",
      company: "Ultium Cells",
      companylogo: require("./assets/images/Ultium-Cells.png"),
      date: "Aug 2023 – Present",
      desc: "Vision Systems Engineer for Ultium Cells / LG Energy Solutions",
      descBullets: [
        "Vision Systems Analysis and Development w/ Python, SpotFire, and Cognex",
        "Defect Detection and Classisfication w/ Deep Learning and Machine Learning"
      ]
    },
    {
      role: "Software Development Engineer",
      company: "Alpine IT",
      companylogo: require("./assets/images/alpine.png"),
      date: "Jan 2023 – May 2023",
      desc: "Full Stack Development w/ React, Ruby on Rails, Python and AWS",
      descBullets: [
        "Developed full-stack web app with React, Ruby on Rails, and Python for optimized inventory management",
        "Utilized AWS for scalable, reliable, and secure cloud infrastructure and integrated machine learning for optimal route identification"
      ]
    },
    {
      role: "Graduate Research Assistant",
      company: "Youngstown State University",
      companylogo: require("./assets/images/ysulogo.png"),
      date: "August 2021 – May 2023",
      desc: [
        "Research Project on Natural Language Processing w/ Advanced Time Series Analysis"
      ],
      descBullets: [
        "Prediction of stock market trends using NLP and Time Series Analysis",
        "Deep Learning model for Stock Market Prediction w/ low RMSE",
        "NLP with 97% accuracy on Tweets, Feature Importance Analysis on Tweets to increase accuracy."
      ]
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: true // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Big Projects",
  subtitle: "STARTUP THAT I AM ADVISING FOR TECHNICAL GROWTH",
  projects: [
    {
      image: require("./assets/images/saayaHealthLogo.webp"),
      projectName: "H.O.P.E",
      projectDesc:
        "Advisor for Technical Growth, and help with innovative ideas",
      footerLink: [
        {
          name: "Visit Website",
          url: "http://build-hope.com/"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/nextuLogo.webp"),
      projectName: "Byte Brain AI",
      projectDesc:
        "Advisor for AI integration and innovative ideas for business growth"
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle:
    "Achievements, Certifications, Award Letters and Some Cool Stuff that I have done !",

  achievementsCards: [
    {
      title: "COMAP 2021",
      subtitle:
        "Top 7% out of 25000+ teams globally in Mathematical Modelling Competion",
      image: require("./assets/images/comap.png"),
      imageAlt: "COMAP Logo",
      footerLink: [
        {
          name: "Award",
          url: "https://drive.google.com/drive/u/0/folders/1YD373A4O5sbRvoBbhXH3uts3hYolWugD"
        },
        {
          name: "YSU Blog",
          url: "https://ysu.edu/news/mathematics-students-score-top-8-international-mathematical-modeling-competition"
        }
      ]
    },
    {
      title: "Data Analytics Certificate",
      subtitle: "Awarded Certificate in Data Analytics in Sprin 2021",
      image: require("./assets/images/ysulogo.png"),
      imageAlt: "ysu logo",
      footerLink: [
        {
          name: "Merit Story",
          url: "https://ysu.edu/about-ysu?merit_story_id=58741942"
        }
      ]
    },
    {
      title: "Hirsch-Satrum Leadership Award ",
      subtitle: "Leadership Award",
      image: require("./assets/images/ysulogo.png"),
      imageAlt: "hirsch ysu award",
      footerLink: [
        {
          name: "YSU Award Banquet Booklet",
          url: "https://issuu.com/youngstownstate/docs/awards_banquet_booklet_2020"
        }
      ]
    },
    {
      title: "MULTICULTURAL LEADERSHIP AWARD",
      subtitle: "Leadership Award",
      image: require("./assets/images/ysulogo.png"),
      imageAlt: "ysu logo",
      footerLink: [
        {
          name: "YSU Award Banquet Booklet",
          url: "https://fliphtml5.com/fqrzg/dgou/basic"
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Blogs",
  displayMediumBlogs: "true", // Set true to display fetched medium blogs instead of hardcoded ones
  blogs: [],
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"
  ),

  talks: [
    {
      title: "Build Actions For Google Assistant",
      subtitle: "Codelab at GDG DevFest Karachi 2019",
      slides_url: "https://bit.ly/saadpasta-slides",
      event_url: "https://www.facebook.com/events/2339906106275053/"
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo"
  ],
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "I am available on almost every social media, you can contact me anywhere. I will get back to you within 24 hours.",
  number: "+1 (330) 891-7764",
  email_address: "aniketkashyyap@gmail.com"
};

// Twitter Section

const twitterDetails = {
  userName: "twitter", //Replace "twitter" with your twitter username without @
  display: false // Set true to display this section, defaults to false
};

const isHireable = true; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
  isHireable
};
